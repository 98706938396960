.weekly-points-cards {
    width: 38vw;
    height: 13vw;
    background-color: #FFFFFF;
    border-left: 7px solid #444BAB;
    margin-left: 1vw;
}

.header-weekly-point-cards {
    font-family: Lato-Regular;
    font-size: 1.15vw;
    font-weight: 700;
    padding-top: 1vh;
    text-align: center;
    color: #4D4F66;
}

.weekly-points-cards-value {
    font-family: Lato-Regular;
    font-weight: bold;
    font-size: 2vw;
    padding-top: 4.5vh;
    color: #000000;
    text-align: center;
}

.box-seperator-line {
    border: 1px solid #E3E3E3;
}

.weekwise-value {
    justify-content: center;
    margin-top: 1vw;
}

.last-3week-section {
    font-family: Lato-Regular;
    font-size: 1.04vw;
    font-weight: 600;
    color: #4D4F66;
    text-align: center;
}

.last-3week-data {
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.56vw;
    color: #000000;
    text-align: center;
    margin-top: 0.5vw;
}