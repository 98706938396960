.subscriptions-chart-section {
    display: flex;
    flex-direction: row;
    margin-top: 3vh;
    padding-left: 1.5vw;
}

.line-chart-container {
    width: 50%;
    background-color: #FFFFFF;
}

.expired-chart-container {
    background-color: #ffffff;
    width: 50%;
    margin-left: 0.5vw;
    padding-bottom: 1vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
}
.doughnut-middle-text {
    font-size: 0.9vw;
}
.doughnut-middle-design-expired {
        border-radius: 100%;
        position: relative;
        top: 54%; 
        margin-top: -10%;
        font-family: Lato-Regular;
        font-size: 2vw;
        font-weight: 700;
        text-align: center;
}
.doughnut-middle-design-points {
    position: relative;
    top: 15vw;  
    left:-17%;
    margin-top: -20%;
    font-family: Lato-Regular;
    font-size: 1.9vw;
    font-weight: 700;
    text-align: center;
}
.progress-chart-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.progress-chart-heading {
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.2vw;
    color: #343A40;
    line-height: 2vh;
    padding-left: 1.5vw;
    padding-top: 1vw;
}
.dropdown-text{
    font-family: Lato-Regular;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2vw;
    line-height: 2vh;
    color: #343A40;
    padding-top:0.3vw ;
}
.chart-info-image {
    height: 1.1vw;
    width: 1.1vw;
    margin-left: 0.4vw;
}
.chart-info-image:not(:hover) + .chart-info {
    display: none;
}
.chart-info {
    margin-left: 0.5vw;
    font-family: "Lato-Regular";
    font-size: 0.9vw;
    background-color: #F3F3F3;
    color:  #343A40;
    font-weight: 400;
    padding: 0.3vw 0.3vw;
}
.points-chart {
    padding-left: 3vw;
    width: 32vw;
}

.points-based-section {
    display: flex;
    flex-direction: row;
    padding-left: 1.5vw;
    margin-top: 2.5vw;
}

.points-chart-container {
    width: 50%;
    height: 32.7vw;
    background-color: #FFFFFF;
    margin-top: 8px;
}

.weekly-data-container {
    width: 50%;
}

.linechart-dropdown-date {
    display: flex;
    flex-direction: row;
    margin:1vw 1vw;
}

.linechart-dropdown-metrics {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.line-chart-axis-title {
    font-family: Lato-Regular;
    font-size: 0.9vw;    
    font-weight: 500;
    padding-left: 0.5vw;
    padding-bottom: 0.8vw;
}
.legend-for-expire-chart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
}
.expired-chart-legend {
    font-family: Lato-Regular;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.legend-circle-icon{
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
    margin-right: 0.15vw;
}
.placed-legend{
    background-color:#7AD68F;
}
.interview-legend{
    background-color:#F1E897;
}
.active-legend{
    background-color:#9DDBE2;
}
.inactive-legend{
    background-color:#E07474;
}

  .i-button{
    padding-left: 0.5vw;
    position: relative;
    bottom: -3px;
  }
  .i-button-tooltip {
    position: relative;
    display: inline-block;
}
.i-button-tooltip .tooltiptext {
    visibility: hidden;
    width: 170px;
    font-family: "Lato-Regular";
    font-size: 0.9vw;
    font-weight: 400;
    background-color: #F3F3F3;
    color:  #343A40;
    text-align: center;
    border-radius: 5px;
    padding: 0.4vw 0.4vw;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
}
.i-button-tooltip:hover .tooltiptext {
    visibility: visible;
}
.calendar-size{
    width: 1.5vw;
    margin-right:0.8vw;
}
.down-arrow{
    width:1.5vw ;
    margin-right: 0.8vw;
}