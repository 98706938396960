.headerAppBar {
  z-index: 10000 !important;
  background-color: white !important;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1) !important;
}
.headerHeading {
  color: #444BAB;
  font-family: Gilroy-ExtraBold;
  font-size: 1.1vw;
  padding-left: 0.8vw;
  margin: 0px;
}
.userName {
  color: #343a40;
  font-size: 1vw;
  font-family: Roboto-Regular;
  margin: 0px;
  cursor: pointer;
}
.option-text {
  color: #7A7A7A;
  font-size: 1.2vw;
  margin-right: 80px;
}

.list-item-icon {
  margin-left: 8px;
}

.link-clickable-pointer-icon {
  cursor: pointer;
}

.collapse-menu-icon {
  width: 38px;
  margin-left: 2px;
}

.list-item-text-problem, .list-item-text-lecture {
    font-family: 'Gilroy-Bold';
    font-size: 0.85vw;
}

.list-item-text-problem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-lecture-duration {
    font-family: 'Gilroy-Medium';
    font-size: 0.9vw;
    color: #868686;
}

.header-solved-and-total-problems {
    font-family: 'Gilroy-Medium';
    font-size: 0.9vw;
    letter-spacing: 0.02em;
    color: #A9A9A9;
    justify-content:right;
}
 
.refer-your-friends {
  background: #f6f7ff;
  padding: 0.7vw 0.9vw;
  margin: 0vw 1vw;
}

.refer-your-friends-text-and-img {
  display: flex;
  flex-direction: row;
}

.refer-your-friends-img {
  width: 53%;
}

.refer-your-friends-and-win-rewards {
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  width: 47%;
}

.refer-your-friends-and-win-rewards > span {
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  color: #d87316;
}

.steps-to-earn-rewards-wrapper {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.steps-to-earn-rewards {
  font-family: Poppins;
  font-size: 12px;
  color: #3a3a3a;
  padding-bottom: 0.5vw;
}

.steps-to-earn-rewards > span {
  padding-right: 0.3vw;
}

.refer-your-friends-collapse-button {
  font-family: Poppins;
  font-weight: bold;
  font-size: 15px;
  padding: 0.2vw;
  cursor: pointer;
}

.refer-your-friends-collapse-button > span {
  font-family: Poppins;
  font-weight: bold;
  font-size: 15px;
  color: #d87316;
  cursor: pointer;
}

.logout-wrapper{
  position:absolute;
  top:50px;
  height:60px;
}
.logout-wrapper>button{
  background: linear-gradient(93.89deg, #444bab 5.65%, #4498ab 100%);
  border: 1px solid #e9e9e9;
  color: #ffffff;
}
#simple-menu > .MuiMenu-paper {
  top: 60px !important;
  box-shadow: none;
  border-width: 5px;
  background-color: #ffffff !important;
}

#simple-menu > div {
  opacity: 51%;
  background-color: #343a40 !important;
}
@media (max-width: 600px) {
    .userName {
        display: none;
    }
    .headerHeading {
        font-size: 17px;
    }
    .list-item-text-problem, .list-item-text-lecture {
        font-size: 14px;
    }
    .header-lecture-duration, .header-solved-and-total-problems{
        font-size: 12px;
    }
}

.ListItemText .MuiTypography-root{
  font-family: Gilroy-Bold !important;
  font-size: 0.83vw;
}
.appBarRightWing{
  margin-right: 1.5vw;
}
.searchBar{
  width: 25vw;
}
.list-item-menu-text{
    font-family: "Lato-Bold";
    font-size: 0.85vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Lato-Regular";
    font-weight: 700;
}