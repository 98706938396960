.login {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5px;
    width:300px;
    text-align: center;
}
.login-form > label{
    font-size: 20px;
}
.email-input {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height:30px;
    padding: 0.7vw 0.5vw;
    color: #393939;
    font-family: Poppins-Regular;
    outline-color: #c7c7d1;
    width: 98%;
    font-size: 20px;
}
.submit-button {
    width: 105%;
    height:55px;
    border-radius: 5px;
    border:none;
    background-color: rgb(71, 161, 212);
    padding: 0.7vw 0.5vw;
    cursor:pointer;
    font-size: 20px;
}
.otp-input {
    margin-top: 10px;
}
.error-msg {
    color: red;
    font-size: 18px;
}

