.date-picker {
    width: 80%;
    height: 100%;
    border-radius: 4px;
    border: 1.5px solid;
    border-color: #c8c4c4;
    padding-left: 0.7vw;
    font-family: "Lato-Regular";
}

.date-picker::placeholder{
    font-size: larger;
}

.react-datepicker-wrapper {
    height: 3.5vw;
    margin-left: 0.55vw;
    margin-top: 0.5vw;
}

.react-datepicker__input-container {
    height: 100%;
}

.discount-coupon-form-container {
    position: absolute;
    border-radius: 4px;
    margin-left: 3vw;
    margin-right: 3vw;
    width: 89%;
    background-color: rgb(250, 247, 247);
}
