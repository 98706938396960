.table-wrapper {
    display: flex;
    column-gap: 4vw;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    height: 3vw;
}

.header-wrapper-underline {
    width: 100%;
    border: 1px solid #e7e7e7;
}

.download-and-send-email {
    display: flex;
    height: 2vw;
    margin-top: 0.5vw;
}

.table-container {
    display: flex;
    margin-top: 5px;
    height: 100%;
}

.heading-horizontal-line {
    position: relative;
    bottom: -0.7vw;
    border: 0.1vw solid #444bab;
    width: 100%;
    background: #444bab;
    align-items: flex-end;
}
.dropdown-input {
    width: 100%;
    text-align: center;
    outline-color: #c7c7d1;
}
.remarks-button {
    width: 70px;
    align-items: center;
    text-align: center;
    margin-bottom: 1.5vw;
    outline-color: #c7c7d1;
}
.add-button {
    width: 100%;
    text-align: center;
    color: #474d54;
}
.remarks-button {
    width: 100%;
    text-align: center;
}

.table-heading-black {
    font-family: "Lato-Black";
    font-style: normal;
    font-weight: 6;
    font-size: 15px;
    color: #4d4f66;
    text-align: center;
    cursor: pointer;
}
.table-heading-blue {
    font-family: "Lato-Black";
    font-style: normal;
    font-weight: 6;
    font-size: 15px;
    color: #444bab;
    text-align: center;
    cursor: pointer;
}
.download {
    color: #444bab;
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 6;
    font-size: 15px;
    cursor: pointer;
}
.send-email {
    background: linear-gradient(93.89deg, #444bab 5.65%, #4498ab 100%);
    color: #ffffff;
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 6;
    cursor: pointer;
}
.count-row {
    display: flex;
    padding: 2vw 2px 1vw;
    justify-content: flex-end;
    font-family: "Lato-Regular";
    font-style: normal;
    color: #444bab;
}

.ag-header-cell-text {
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #4d4f66;
}

.ag-header-cell-label {
    display: flex;
    justify-content: center;
}

.ag-root {
    width: 0px;
}
