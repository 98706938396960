.extend-subscription-form-container {
    position: absolute;
    border-radius: 4px;
    margin-left: 3vw;
    margin-right: 3vw;
    width: inherit;
    background-color: rgb(250, 247, 247);
}

.view-payment-history-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5vw;
}
.payment-history-button {
    padding-bottom: 2vw;
}
.payment-history-button button {
    background: rgb(68, 75, 171);
    padding: 1vw;
    border: none;
    color: #ffffff;
    font-size: 1.2vw;
    cursor: pointer;
}
.view-payment-history-wrapper table {
    border-collapse: collapse;
    width: 100%;
}
.view-payment-history-wrapper th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    font-size: 1.2vw;
}
.view-payment-history-wrapper td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    font-size: 1vw;
}
.error-text {
    color: red;
    font-size: 1.2vw;
    padding-bottom: 1vw;
}

.extend-subscription-payment-results-container {
    position: relative;
    width: fit-content;
    padding: 16px 16px 8px;
    border-radius: 12px;
    background-color: #dddddd;
    margin: auto;

    * {
        margin: 0;
        padding: 0;
    }

    .extend-subscription-payment-result-item {
        display: grid;
        grid-template-columns: auto 20px;
        gap: 16px;
        align-items: center;
        justify-items: center;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 4px;
        }

        p {
            width: 100%;
        }
    }

    .extend-subscription-payment-close-modal-btn {
        position: absolute;
        right: -10px;
        top: -10px;
        transition: all 300ms ease;

        font-size: 24px;
        padding: 4px;
        background-color: #ffffff;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }
}
