.wrapper {
    display: flex;
    flex-wrap: inherit;
    padding:1.2vw 1vw;
}

.header-overview {
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.7vw;
    line-height: 33px;
    padding-top: 1.2vw;
    padding-left: 1.5vw;
    color: #4D4F66;
}

.subscribed_user_box {
    border-left: 7px solid #0F5CB2;
    flex-wrap: inherit;
}

.inactive_user_box {
    border-left: 7px solid #E17575;
}

.ready_to_interview_box {
    border-left: 7px solid #E6E87D;
}

.placed_box {
    border-left: 7px solid #67D280;
}

.card-headers {
    display: flex;
    column-gap: 0.4vw;
}

.cards-heading-text {
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.2vw;
    line-height: 2vh;
    color: #343A40;
    align-self: center;
}

.image-iIcon {
    flex-direction: column;
}

.users-value {
    display: flex;
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.5vw;
    line-height: 8vh;
    justify-content: space-around;
}

.last-30days_percentage {
    display: flex;
    font-family: Lato-Regular;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1vh;
    justify-content: space-around;
    color: #747577;
    margin-top: 0.2vw;
}

.overview-cards-hover-info {
    font-family: 'Lato-Regular';
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 12px;
    color: #343A40;
    border-radius: 5px;
    padding: 0.4vw 0.4vw;
}
