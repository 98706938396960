.calendar-modal-wrapper {
	display: flex;
	justify-content: center;
	position: relative;
	width: fit-content;
	height: fit-content;
	padding: 1vw;
	background: #FFFFFF;
	box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
	border-radius: 12px;
}

.modalBodyWrapper {
	display: flex;
	flex-direction: column;
}

.error-input {
	margin-bottom: 0;
	margin-top: 1vw;
	color: #FF5050
}

.successCancelButton{
	display: flex;
}

.setDateRange {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.2vw 0.8vw;
	color: #FFFFFF;
	width: 9vw;
	height: 3vw;
	border-width: 1px;
	background: #444BAB;
	border-radius: 8px;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
	font-size: 1.2vw;
}

.cancelDateRange {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.8vw 1.6vw;
	border-width: 1px;
	width: 7.8vw;
	height: 3vw;
	background: #F8F7FA;
	border-radius: 8px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 8px;
	font-size: 1.2vw;
}

.rdrNextPrevButton {
	display: block;
	width: 4vw !important;
	height: 4vw !important;
	border: 0;
	border-radius: 5px;
	padding: 1vw !important;
	padding-left: 1.5vw !important;
	background: #EFF2F7;
}

.rdrMonthPicker,
.rdrYearPicker {
	margin: 0 0.5vw;
	background-color: #F7F8FF;
	border-radius: 0.6vw;

}

.rdrMonthAndYearWrapper select {
	align-items: center;
	font-size: 1.2vw;
	padding: 1vw 3vw 1vw 1vw !important;
}

.rdrMonthAndYearWrapper {
	align-items: center;
	height: auto !important;
	padding-top: 0vw !important;
}

.rdrCalendarWrapper {
	color: #000000;
	font-size: 1.2vw !important;
}

.rdrNextButton i {
	margin: 0px !important;
}

.rdrInputRange {
	display: none;
}

@media (max-width: 600px) {
	.invalidText {
		font-size: 14px;
	}
}