@font-face {
	font-family: "Lato-Bold";
	src: url('./resources/fonts/Lato-Bold.ttf');
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url('./resources/fonts/Poppins-SemiBold.ttf');
}

@font-face {
	font-family: "Poppins-Medium";
	src: url('./resources/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: "Lato-Black";
    src: url('./resources/fonts/Lato-Black.ttf');
}

@font-face {
    font-family: "Lato-Italic";
    src: url("./resources/fonts/Lato-Italic.ttf");
}

@font-face {
    font-family: "FiraCode-Bold";
    src: url("./resources/fonts/FiraCode-Bold.ttf");
}

@font-face {
    font-family: "FiraCode-Light";
    src: url("./resources/fonts/FiraCode-Light.ttf");
}

@font-face {
    font-family: "FiraCode-Medium";
    src: url("./resources/fonts/FiraCode-Medium.ttf");
}

@font-face {
    font-family: "Gilroy-ExtraBold";
    src: url("./resources/fonts/Gilroy-ExtraBold.ttf");
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("./resources/fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("./resources/fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("./resources/fonts/Gilroy-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("./resources/fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("./resources/fonts/Poppins-Italic.otf");
}
@font-face {
    font-family: Gilroy-Bold;
    src: url("./resources/fonts/Gilroy-Bold.ttf");
}
@font-face {
    font-family: Poppins;
    src: url("./resources/fonts/Poppins.ttf");
}
@font-face {
    font-family: Lato-Light;
    src: url("./resources/fonts/Lato-Light.ttf");
}
@font-face {
    font-family: Lato-Bold;
    src: url("./resources/fonts/Lato-Bold.ttf");
}
@font-face {
    font-family: Lato-Regular;
    src: url("./resources/fonts/Lato-Regular.ttf");
}

@font-face {
    font-family: Montserrat-Bold;
    src: url("./resources/fonts/Montserrat-Bold.ttf");
}
@font-face {
    font-family: Montserrat-Black;
    src: url("./resources/fonts/Montserrat-Black.ttf");
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url("./resources/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
    font-family: Roboto-Light;
    src: url("./resources/fonts/Roboto-Light.ttf");
}
@font-face {
    font-family: Roboto-Bold;
    src: url("./resources/fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: Roboto-Regular;
    src: url("./resources/fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: Iceberg-Regular;
    src: url("./resources/fonts/Iceberg-Regular.ttf");
}