.chart-box {
    position: relative;
}

.bar-chart-select-wrapper {
    display: flex;
    background-color: #FFF;
    border-radius: 3px;
    align-items: center;
    box-shadow: 0px 0.25px 4px rgba(0, 0, 0, 0.25);
    padding: 0 0.5vw;
    cursor: pointer;
}
.bar-chart-select-wrapper b {
    font-family: "Lato-Regular";
    font-size: 1vw;
    color: #343A40;
}
.bar-chart-menu-days-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 4px;
    padding: 1vw 0;
    width: 65%;
    z-index: 1;
    position: absolute;
    box-shadow: 0px 0.25px 4px rgba(0, 0, 0, 0.25);
}

.bar-chart-menu-days-wrapper p {
    font-family: "Roboto-Regular";
    font-size: 1vw;
    color: #343A40;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 1.8;
    padding-left: 1vw;
}

.bar-chart-menu-days-wrapper p:hover {
    background-color: #D1E2EA;
}
